import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import ContactForm from '../../../core/sysext/Boutique/containers/Contact/components/Form';
import Backoffice from '../../../core/sysext/CMS/containers/Backoffice';

const ContactsPage: FC<PageProps & { id: string }> = props => {
  const { id, location } = props;
  if (!id) {
    return <div>lid est obligatoire</div>;
  }

  return (
    <Backoffice location={location}>
      <ContactForm docId={id} {...props} />
    </Backoffice>
  );
};

export default ContactsPage;
